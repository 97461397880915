import { Navigate, Route, Routes } from 'react-router-dom';

import { DEDICATED_APP_NAME, PATH } from '@constants';
import { contextNamesSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';
import { useAuthentication } from '@hooks/useAuthentication';
import { path } from '@utils';

import * as Page from '@pages';
import { OrganizationCreditsProvider } from '@components/Providers';
import { ScrollToTop } from '@components/ScrollToTop';

import { AppsRoutes } from './AppsRoutes';
import { BillingRoutes } from './BillingRoutes';
import { ClusterSettingsRoutes } from './ClusterSettingsRoutes';
import { JobRoutes } from './JobRoutes';
import { OrganizationSettingsRoutes } from './OrganizationSettingsRoutes';
import { ProjectSettingsRoutes } from './ProjectSettingsRoutes';

export const ApplicationRoutes = () => {
  const { organizationName } = useSelector(contextNamesSelector);

  const {
    isAuthenticated,
    isClusterRequired,
    isOnboardingRequired,
    termsAccepted,
    authError,
    user,
  } = useAuthentication();
  const { isOrganizationManager } = useTenantRole();
  const isTermsFetching = termsAccepted === undefined;

  if (authError && !user) {
    return (
      <Routes>
        <Route path="*" element={<Page.AuthError error={authError} />} />
      </Routes>
    );
  }

  if (isAuthenticated && isTermsFetching) {
    return (
      <Routes>
        <Route path="*" element={<Page.Loader />} />
      </Routes>
    );
  }

  if (isAuthenticated && !termsAccepted) {
    return (
      <Routes>
        <Route path="*" element={<Page.UserAgreeTerms />} />
      </Routes>
    );
  }

  if (isOnboardingRequired) {
    return (
      <Routes>
        <Route path={PATH.ONBOARDING} element={<Page.Onboarding />} />
        <Route path="*" element={<Navigate replace to={PATH.ONBOARDING} />} />
      </Routes>
    );
  }

  if (isClusterRequired) {
    return (
      <Routes>
        <Route
          path={path.catchAll(PATH.ORGANIZATION_SETTINGS)}
          element={<OrganizationSettingsRoutes />}
        />
        <Route
          path={PATH.ORGANIZATIONS_INVITES}
          element={<Page.OrganizationsInvites />}
        />
        <Route path="*" element={<Page.ClusterRequired />} />
      </Routes>
    );
  }

  if (isAuthenticated) {
    return (
      /**
       * Temporary solution until react-router Outlet will be implemented
       */
      <OrganizationCreditsProvider>
        <ScrollToTop />
        <Routes>
          <Route
            path={path.catchAll(path.app())}
            element={<JobRoutes isJobPage={false} />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.LLM_INFERENCE)}
            element={<Page.LlmInferenceConstructor />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.TEXT_EMBEDDING_INFERENCE)}
            element={<Page.TextEmbeddingsInferenceConstructor />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.DIFY)}
            element={<Page.DifyConstructor />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.POSTGRESQL)}
            element={<Page.PostgresqlConstructor />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.PRIVATE_GPT)}
            element={<Page.PrivateGptConstructor />}
          />
          <Route
            path={path.installApp(DEDICATED_APP_NAME.STABLE_DIFFUSION)}
            element={<Page.StableDiffusionConstructor />}
          />
          <Route path={path.installApp()} element={<Page.AppConstructor />} />
          <Route path={path.catchAll(PATH.APPS)} element={<AppsRoutes />} />
          <Route
            path={path.catchAll(PATH.STORAGE)}
            element={<Page.Storage />}
          />
          <Route path={PATH.BUCKETS} element={<Page.Buckets />} />
          <Route
            path={path.catchAll(path.bucket())}
            element={<Page.Bucket />}
          />
          <Route path={PATH.DISKS} element={<Page.Disks />} />
          <Route path={path.catchAll(PATH.IMAGES)} element={<Page.Images />} />
          <Route path={PATH.SECRETS} element={<Page.Secrets />} />
          <Route
            path={path.catchAll(path.job())}
            element={<JobRoutes isJobPage />}
          />
          {isOrganizationManager && organizationName && (
            <Route
              path={path.catchAll(PATH.BILLING)}
              element={<BillingRoutes />}
            />
          )}
          <Route path={PATH.JOBS} element={<Page.Jobs />} />
          <Route path={PATH.NEW_JOB} element={<Page.JobConstructor />} />
          <Route path={path.bake()} element={<Page.FlowBakeTasks />} />
          <Route path={path.flowBakes()} element={<Page.FlowBakes />} />
          <Route path={PATH.FLOWS} element={<Page.Flows />} />
          <Route path={PATH.CREATE_PROJECT} element={<Page.CreateProject />} />
          <Route
            path={PATH.ORGANIZATIONS_INVITES}
            element={<Page.OrganizationsInvites />}
          />
          <Route
            path={PATH.CREATE_ORGANIZATION}
            element={<Page.CreateOrganization />}
          />
          <Route
            path={path.catchAll(PATH.CLUSTER_SETTINGS)}
            element={<ClusterSettingsRoutes />}
          />
          <Route
            path={path.catchAll(PATH.ORGANIZATION_SETTINGS)}
            element={<OrganizationSettingsRoutes />}
          />
          <Route
            path={path.catchAll(PATH.PROJECT_SETTINGS)}
            element={<ProjectSettingsRoutes />}
          />
          <Route path="*" element={<Navigate replace to={PATH.HOME} />} />
        </Routes>
      </OrganizationCreditsProvider>
    );
  }

  return (
    <Routes>
      <Route path={PATH.AUTH} element={<Page.Auth />} />
      <Route path="*" element={<Navigate replace to={PATH.AUTH} />} />
    </Routes>
  );
};
