import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import {
  faChevronLeft,
  faLockKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { ResponseError } from '@typings';
import { DEDICATED_APP_NAME, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  getInstallingAppImage,
  invariant,
  normalizeFormErrors,
  path,
} from '@utils';
import { dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  JobConstructorNavigator,
  JobConstructorSecretModal,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { AppConstructorNavigationProvider } from '@components/Providers';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  hfToken: z.string().min(1),
  apiReplicaCount: z.string(),
  apiIngressEnabled: z.boolean().optional(),
  modelHFName: z.string().min(1),
  modelFiles: z.string(),
  stablestudioEnabled: z.boolean().optional(),
  stablestudioPresetName: z.string().min(1),
  presetName: z.string().min(1),
  name: z.string(),
});

export const StableDiffusionConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      stablestudioEnabled: true,
      apiIngressEnabled: true,
    },
  });

  const [loading, setLoading] = useState(false);

  const appName = DEDICATED_APP_NAME.STABLE_DIFFUSION;
  const { control, register, formState, setValue, handleSubmit } = methods;
  const { name, title, image } = dedicatedApps.find(
    ({ name }) => name === appName,
  )!;
  const tags = ['kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { powerlessPresetName } = usePowerlessResourcePresetName({ appName });

  const handleSecretAdd = ({ value }: { value: string }) =>
    setValue('hfToken', value);

  const handleFormSubmit = handleSubmit(
    async ({
      name,
      presetName,
      hfToken,
      apiReplicaCount,
      modelFiles,
      modelHFName,
      stablestudioPresetName,
      apiIngressEnabled,
      stablestudioEnabled,
    }) => {
      try {
        invariant(clusterName);
        invariant(projectName);

        setLoading(true);

        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;
        const secretPath = path.create(
          clusterName,
          organizationName,
          projectName,
          hfToken,
          { prefix: '' },
        );
        const hfTokenSecret = hfToken ? `secret://${secretPath}` : null;

        const appCommand = new AppCommand();

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/app-stable-diffusion ${appName} ${formattedName} charts/app-stable-diffusion --timeout=15m`,
          )
          .set('api.replicaCount', apiReplicaCount)
          .set('api.ingress.enabled', apiIngressEnabled)
          .set('preset_name', presetName)
          .set('api.env.HUGGING_FACE_HUB_TOKEN', hfTokenSecret)
          .set('stablestudio.enabled', stablestudioEnabled)
          .set('stablestudio.preset_name', stablestudioPresetName)
          .set('model.modelHFName', modelHFName)
          .set('model.modelFiles', modelFiles)
          .compose();

        await createJob({
          organizationName,
          clusterName,
          projectName,
          name: `${formattedName}-install`,
          command,
          tags,
          image: getInstallingAppImage(image),
          presetName: powerlessPresetName,
          passConfig: true,
        });

        toast.success(`Installing ${title} App`);

        navigate(PATH.APPS, { replace: true });
      } catch (error) {
        toast.error((error as ResponseError).error);
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <Layout>
      <div slot="header" className="flex min-w-0 items-center gap-4">
        <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
        <Link
          variant="ghost"
          to={PATH.APPS}
          className="h-auto p-0 text-[24px] text-neural-03"
        >
          <Icon icon={faChevronLeft} className="h-10 w-10" />
        </Link>
        <h3 className="truncate text-h4 text-white">
          Install {as(title, appName)} App
        </h3>
      </div>
      <Layout.Content className="flex gap-10">
        <AppConstructorNavigationProvider>
          <JobConstructorNavigator />
          <FormProvider {...methods}>
            <form
              className="flex flex-1 justify-center"
              onSubmit={handleFormSubmit}
            >
              <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20">
                <JobConstructorSection name="resources">
                  <JobPresetField
                    note="Preset name used to run Stable Diffusion web app. It is recommended to use GPU-accelerated machines"
                    error={errors.presetName}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="configuration">
                  <Field.Input
                    {...register('modelHFName')}
                    required
                    label="Model Name"
                    className="w-full"
                    note="Huggingface model name"
                    error={errors.modelHFName}
                  />
                  <Field.Input
                    {...register('modelFiles')}
                    label="Model Files"
                    className="w-full"
                    note="HuggingFace model files, model weights, comma-separated"
                    error={errors.modelFiles}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="api">
                  <div className="flex w-full">
                    <Controller
                      name="hfToken"
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const isSecretAdded = !!field.value;
                        return (
                          <Field.Input
                            {...field}
                            disabled
                            required
                            containerClassName="flex-1"
                            label="HuggingFace Token"
                            className={clsx(
                              'w-full rounded-r-none !text-rebecca',
                              { 'pl-9': isSecretAdded },
                            )}
                            labelClassName="!text-neural-04"
                            note="HuggingFace token used to pull tokenizer into Stable Diffusion app"
                            error={error?.message}
                          >
                            {isSecretAdded && (
                              <div
                                slot="icon"
                                className="absolute left-3 top-5"
                              >
                                <Icon
                                  icon={faLockKeyhole}
                                  className="text-neural-04"
                                />
                              </div>
                            )}
                          </Field.Input>
                        );
                      }}
                    />
                    <Modal
                      content={
                        <JobConstructorSecretModal
                          isNameRequired={false}
                          onAdd={handleSecretAdd}
                        />
                      }
                    >
                      <Button className="h-14 rounded-l-none capitalize">
                        Choose secret
                      </Button>
                    </Modal>
                  </div>
                  <Field.Input
                    {...register('apiReplicaCount')}
                    label="Replica Count"
                    type="number"
                    inputMode="numeric"
                    note="Number of API service replicas"
                    error={errors.apiReplicaCount}
                  />
                  <Field.Checkbox {...register('stablestudioEnabled')}>
                    Ingress Enabled
                  </Field.Checkbox>
                </JobConstructorSection>
                <JobConstructorSection name="advanced">
                  <Field.Checkbox {...register('apiIngressEnabled')}>
                    Enable StableStudio UI playground
                  </Field.Checkbox>
                  <JobPresetField
                    label="StableStudio Preset"
                    name="stablestudioPresetName"
                    error={errors.stablestudioPresetName}
                  />
                </JobConstructorSection>
                <JobConstructorSection name="metadata">
                  <Field.Input
                    {...register('name')}
                    label="Name"
                    className="w-full"
                    note="App name"
                    error={errors.name}
                  />
                </JobConstructorSection>
                <Modal.Footer sticky className="-mt-8 px-0">
                  <Button
                    type="submit"
                    loading={loading}
                    className="px-10 capitalize"
                  >
                    Install app
                  </Button>
                </Modal.Footer>
              </Theme.Container>
            </form>
          </FormProvider>
        </AppConstructorNavigationProvider>
      </Layout.Content>
    </Layout>
  );
};
